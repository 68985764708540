"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Forms API
 * API for forms management
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmissionsApi = exports.SubmissionsApiFactory = exports.SubmissionsApiFp = exports.SubmissionsApiAxiosParamCreator = exports.FormsApi = exports.FormsApiFactory = exports.FormsApiFp = exports.FormsApiAxiosParamCreator = exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = exports.AppApi = exports.AppApiFactory = exports.AppApiFp = exports.AppApiAxiosParamCreator = exports.WidgetType = exports.TemplateAccessType = exports.FormStatus = exports.FormAccessType = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.FormAccessType = {
    Private: 'PRIVATE',
    Public: 'PUBLIC'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.FormStatus = {
    Enabled: 'ENABLED',
    Disabled: 'DISABLED',
    Deleted: 'DELETED'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.TemplateAccessType = {
    Public: 'PUBLIC',
    Private: 'PRIVATE'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.WidgetType = {
    FormTitle: 'FormTitle',
    FullName: 'FullName',
    Email: 'Email',
    Address: 'Address',
    DateOfBirth: 'DateOfBirth',
    Date: 'Date',
    PhoneNumber: 'PhoneNumber',
    ImageUpload: 'ImageUpload',
    Signature: 'Signature',
    ShortText: 'ShortText',
    LongText: 'LongText',
    SingleChoice: 'SingleChoice',
    MultipleChoice: 'MultipleChoice',
    SingleCheckbox: 'SingleCheckbox',
    DropDown: 'DropDown',
    Number: 'Number',
    InputTable: 'InputTable',
    Gender: 'Gender',
    Allergies: 'Allergies',
    MedicalConditions: 'MedicalConditions'
};
/**
 * AppApi - axios parameter creator
 * @export
 */
var AppApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetDbStatus: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/db_status";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/healthCheck";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHello: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.AppApiAxiosParamCreator = AppApiAxiosParamCreator;
/**
 * AppApi - functional programming interface
 * @export
 */
var AppApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.AppApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetDbStatus: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appGetDbStatus(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appGetHealthCheck(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHello: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appGetHello(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.AppApiFp = AppApiFp;
/**
 * AppApi - factory interface
 * @export
 */
var AppApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.AppApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetDbStatus: function (options) {
            return localVarFp.appGetDbStatus(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            return localVarFp.appGetHealthCheck(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHello: function (options) {
            return localVarFp.appGetHello(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.AppApiFactory = AppApiFactory;
/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
var AppApi = /** @class */ (function (_super) {
    __extends(AppApi, _super);
    function AppApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    AppApi.prototype.appGetDbStatus = function (options) {
        var _this = this;
        return (0, exports.AppApiFp)(this.configuration).appGetDbStatus(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    AppApi.prototype.appGetHealthCheck = function (options) {
        var _this = this;
        return (0, exports.AppApiFp)(this.configuration).appGetHealthCheck(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    AppApi.prototype.appGetHello = function (options) {
        var _this = this;
        return (0, exports.AppApiFp)(this.configuration).appGetHello(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AppApi;
}(base_1.BaseAPI));
exports.AppApi = AppApi;
/**
 * DefaultApi - axios parameter creator
 * @export
 */
var DefaultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationCheck: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/integrationsCheck";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.DefaultApiAxiosParamCreator = DefaultApiAxiosParamCreator;
/**
 * DefaultApi - functional programming interface
 * @export
 */
var DefaultApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.DefaultApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationCheck: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsGetIntegrationCheck(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.DefaultApiFp = DefaultApiFp;
/**
 * DefaultApi - factory interface
 * @export
 */
var DefaultApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.DefaultApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationCheck: function (options) {
            return localVarFp.integrationsGetIntegrationCheck(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.DefaultApiFactory = DefaultApiFactory;
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.integrationsGetIntegrationCheck = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).integrationsGetIntegrationCheck(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DefaultApi;
}(base_1.BaseAPI));
exports.DefaultApi = DefaultApi;
/**
 * FormsApi - axios parameter creator
 * @export
 */
var FormsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} locationId
         * @param {CloneFormDto} cloneFormDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsCreate: function (locationId, cloneFormDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('formsCreate', 'locationId', locationId);
                    // verify required parameter 'cloneFormDto' is not null or undefined
                    (0, common_1.assertParamExists)('formsCreate', 'cloneFormDto', cloneFormDto);
                    localVarPath = "/locations/{location_id}/forms"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(cloneFormDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsDelete: function (locationId, formId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('formsDelete', 'locationId', locationId);
                    // verify required parameter 'formId' is not null or undefined
                    (0, common_1.assertParamExists)('formsDelete', 'formId', formId);
                    localVarPath = "/locations/{location_id}/forms/{form_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("form_id", "}"), encodeURIComponent(String(formId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsDownloadData: function (locationId, formId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('formsDownloadData', 'locationId', locationId);
                    // verify required parameter 'formId' is not null or undefined
                    (0, common_1.assertParamExists)('formsDownloadData', 'formId', formId);
                    localVarPath = "/locations/{location_id}/forms/{form_id}/submissionsCSV"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("form_id", "}"), encodeURIComponent(String(formId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsFindTemplates: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('formsFindTemplates', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/forms/templates"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} formId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsFormAccess: function (formId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'formId' is not null or undefined
                    (0, common_1.assertParamExists)('formsFormAccess', 'formId', formId);
                    localVarPath = "/formAccess/{form_id}"
                        .replace("{".concat("form_id", "}"), encodeURIComponent(String(formId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} formId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsFormFillInEntryPoint: function (formId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'formId' is not null or undefined
                    (0, common_1.assertParamExists)('formsFormFillInEntryPoint', 'formId', formId);
                    localVarPath = "/forms/{form_id}"
                        .replace("{".concat("form_id", "}"), encodeURIComponent(String(formId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsGetForm: function (locationId, formId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('formsGetForm', 'locationId', locationId);
                    // verify required parameter 'formId' is not null or undefined
                    (0, common_1.assertParamExists)('formsGetForm', 'formId', formId);
                    localVarPath = "/locations/{location_id}/forms/{form_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("form_id", "}"), encodeURIComponent(String(formId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {FormStatus} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsGetFormsForLocations: function (locationId, status, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('formsGetFormsForLocations', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/forms"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (status !== undefined) {
                        localVarQueryParameter['status'] = status;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<FormImportPlanDto>} formImportPlanDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsGetImportPlan: function (locationId, formImportPlanDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('formsGetImportPlan', 'locationId', locationId);
                    // verify required parameter 'formImportPlanDto' is not null or undefined
                    (0, common_1.assertParamExists)('formsGetImportPlan', 'formImportPlanDto', formImportPlanDto);
                    localVarPath = "/locations/{location_id}/forms/import/plan"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(formImportPlanDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} patientRecordId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsGetPrivateFormContent: function (locationId, formId, patientRecordId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('formsGetPrivateFormContent', 'locationId', locationId);
                    // verify required parameter 'formId' is not null or undefined
                    (0, common_1.assertParamExists)('formsGetPrivateFormContent', 'formId', formId);
                    // verify required parameter 'patientRecordId' is not null or undefined
                    (0, common_1.assertParamExists)('formsGetPrivateFormContent', 'patientRecordId', patientRecordId);
                    localVarPath = "/locations/{location_id}/forms/{form_id}/patientRecords/{patient_record_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("form_id", "}"), encodeURIComponent(String(formId)))
                        .replace("{".concat("patient_record_id", "}"), encodeURIComponent(String(patientRecordId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {FormImportDto} formImportDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsImportForms: function (locationId, formImportDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('formsImportForms', 'locationId', locationId);
                    // verify required parameter 'formImportDto' is not null or undefined
                    (0, common_1.assertParamExists)('formsImportForms', 'formImportDto', formImportDto);
                    localVarPath = "/locations/{location_id}/forms/import"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(formImportDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {UpdateFormDataDto} updateFormDataDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsPreviewForm: function (locationId, formId, updateFormDataDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('formsPreviewForm', 'locationId', locationId);
                    // verify required parameter 'formId' is not null or undefined
                    (0, common_1.assertParamExists)('formsPreviewForm', 'formId', formId);
                    // verify required parameter 'updateFormDataDto' is not null or undefined
                    (0, common_1.assertParamExists)('formsPreviewForm', 'updateFormDataDto', updateFormDataDto);
                    localVarPath = "/locations/{location_id}/forms/{form_id}/preview"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("form_id", "}"), encodeURIComponent(String(formId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateFormDataDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} patientRecordId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsSubmitPrivateForm: function (locationId, formId, patientRecordId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('formsSubmitPrivateForm', 'locationId', locationId);
                    // verify required parameter 'formId' is not null or undefined
                    (0, common_1.assertParamExists)('formsSubmitPrivateForm', 'formId', formId);
                    // verify required parameter 'patientRecordId' is not null or undefined
                    (0, common_1.assertParamExists)('formsSubmitPrivateForm', 'patientRecordId', patientRecordId);
                    localVarPath = "/locations/{location_id}/forms/{form_id}/patientRecords/{patient_record_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("form_id", "}"), encodeURIComponent(String(formId)))
                        .replace("{".concat("patient_record_id", "}"), encodeURIComponent(String(patientRecordId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {UpdateFormDataDto} updateFormDataDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsUpdateFormData: function (locationId, formId, updateFormDataDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('formsUpdateFormData', 'locationId', locationId);
                    // verify required parameter 'formId' is not null or undefined
                    (0, common_1.assertParamExists)('formsUpdateFormData', 'formId', formId);
                    // verify required parameter 'updateFormDataDto' is not null or undefined
                    (0, common_1.assertParamExists)('formsUpdateFormData', 'updateFormDataDto', updateFormDataDto);
                    localVarPath = "/locations/{location_id}/forms/{form_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("form_id", "}"), encodeURIComponent(String(formId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateFormDataDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.FormsApiAxiosParamCreator = FormsApiAxiosParamCreator;
/**
 * FormsApi - functional programming interface
 * @export
 */
var FormsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.FormsApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} locationId
         * @param {CloneFormDto} cloneFormDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsCreate: function (locationId, cloneFormDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.formsCreate(locationId, cloneFormDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsDelete: function (locationId, formId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.formsDelete(locationId, formId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsDownloadData: function (locationId, formId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.formsDownloadData(locationId, formId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsFindTemplates: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.formsFindTemplates(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} formId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsFormAccess: function (formId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.formsFormAccess(formId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} formId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsFormFillInEntryPoint: function (formId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.formsFormFillInEntryPoint(formId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsGetForm: function (locationId, formId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.formsGetForm(locationId, formId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {FormStatus} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsGetFormsForLocations: function (locationId, status, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.formsGetFormsForLocations(locationId, status, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<FormImportPlanDto>} formImportPlanDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsGetImportPlan: function (locationId, formImportPlanDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.formsGetImportPlan(locationId, formImportPlanDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} patientRecordId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsGetPrivateFormContent: function (locationId, formId, patientRecordId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.formsGetPrivateFormContent(locationId, formId, patientRecordId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {FormImportDto} formImportDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsImportForms: function (locationId, formImportDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.formsImportForms(locationId, formImportDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {UpdateFormDataDto} updateFormDataDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsPreviewForm: function (locationId, formId, updateFormDataDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.formsPreviewForm(locationId, formId, updateFormDataDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} patientRecordId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsSubmitPrivateForm: function (locationId, formId, patientRecordId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.formsSubmitPrivateForm(locationId, formId, patientRecordId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {UpdateFormDataDto} updateFormDataDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsUpdateFormData: function (locationId, formId, updateFormDataDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.formsUpdateFormData(locationId, formId, updateFormDataDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.FormsApiFp = FormsApiFp;
/**
 * FormsApi - factory interface
 * @export
 */
var FormsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.FormsApiFp)(configuration);
    return {
        /**
         *
         * @param {string} locationId
         * @param {CloneFormDto} cloneFormDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsCreate: function (locationId, cloneFormDto, options) {
            return localVarFp.formsCreate(locationId, cloneFormDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsDelete: function (locationId, formId, options) {
            return localVarFp.formsDelete(locationId, formId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsDownloadData: function (locationId, formId, options) {
            return localVarFp.formsDownloadData(locationId, formId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsFindTemplates: function (locationId, options) {
            return localVarFp.formsFindTemplates(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} formId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsFormAccess: function (formId, options) {
            return localVarFp.formsFormAccess(formId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} formId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsFormFillInEntryPoint: function (formId, options) {
            return localVarFp.formsFormFillInEntryPoint(formId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsGetForm: function (locationId, formId, options) {
            return localVarFp.formsGetForm(locationId, formId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {FormStatus} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsGetFormsForLocations: function (locationId, status, options) {
            return localVarFp.formsGetFormsForLocations(locationId, status, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<FormImportPlanDto>} formImportPlanDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsGetImportPlan: function (locationId, formImportPlanDto, options) {
            return localVarFp.formsGetImportPlan(locationId, formImportPlanDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} patientRecordId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsGetPrivateFormContent: function (locationId, formId, patientRecordId, options) {
            return localVarFp.formsGetPrivateFormContent(locationId, formId, patientRecordId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {FormImportDto} formImportDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsImportForms: function (locationId, formImportDto, options) {
            return localVarFp.formsImportForms(locationId, formImportDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {UpdateFormDataDto} updateFormDataDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsPreviewForm: function (locationId, formId, updateFormDataDto, options) {
            return localVarFp.formsPreviewForm(locationId, formId, updateFormDataDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} patientRecordId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsSubmitPrivateForm: function (locationId, formId, patientRecordId, options) {
            return localVarFp.formsSubmitPrivateForm(locationId, formId, patientRecordId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {UpdateFormDataDto} updateFormDataDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsUpdateFormData: function (locationId, formId, updateFormDataDto, options) {
            return localVarFp.formsUpdateFormData(locationId, formId, updateFormDataDto, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.FormsApiFactory = FormsApiFactory;
/**
 * FormsApi - object-oriented interface
 * @export
 * @class FormsApi
 * @extends {BaseAPI}
 */
var FormsApi = /** @class */ (function (_super) {
    __extends(FormsApi, _super);
    function FormsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} locationId
     * @param {CloneFormDto} cloneFormDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    FormsApi.prototype.formsCreate = function (locationId, cloneFormDto, options) {
        var _this = this;
        return (0, exports.FormsApiFp)(this.configuration).formsCreate(locationId, cloneFormDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    FormsApi.prototype.formsDelete = function (locationId, formId, options) {
        var _this = this;
        return (0, exports.FormsApiFp)(this.configuration).formsDelete(locationId, formId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    FormsApi.prototype.formsDownloadData = function (locationId, formId, options) {
        var _this = this;
        return (0, exports.FormsApiFp)(this.configuration).formsDownloadData(locationId, formId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    FormsApi.prototype.formsFindTemplates = function (locationId, options) {
        var _this = this;
        return (0, exports.FormsApiFp)(this.configuration).formsFindTemplates(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    FormsApi.prototype.formsFormAccess = function (formId, options) {
        var _this = this;
        return (0, exports.FormsApiFp)(this.configuration).formsFormAccess(formId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    FormsApi.prototype.formsFormFillInEntryPoint = function (formId, options) {
        var _this = this;
        return (0, exports.FormsApiFp)(this.configuration).formsFormFillInEntryPoint(formId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} formId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    FormsApi.prototype.formsGetForm = function (locationId, formId, options) {
        var _this = this;
        return (0, exports.FormsApiFp)(this.configuration).formsGetForm(locationId, formId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {FormStatus} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    FormsApi.prototype.formsGetFormsForLocations = function (locationId, status, options) {
        var _this = this;
        return (0, exports.FormsApiFp)(this.configuration).formsGetFormsForLocations(locationId, status, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {Array<FormImportPlanDto>} formImportPlanDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    FormsApi.prototype.formsGetImportPlan = function (locationId, formImportPlanDto, options) {
        var _this = this;
        return (0, exports.FormsApiFp)(this.configuration).formsGetImportPlan(locationId, formImportPlanDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} formId
     * @param {string} patientRecordId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    FormsApi.prototype.formsGetPrivateFormContent = function (locationId, formId, patientRecordId, options) {
        var _this = this;
        return (0, exports.FormsApiFp)(this.configuration).formsGetPrivateFormContent(locationId, formId, patientRecordId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {FormImportDto} formImportDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    FormsApi.prototype.formsImportForms = function (locationId, formImportDto, options) {
        var _this = this;
        return (0, exports.FormsApiFp)(this.configuration).formsImportForms(locationId, formImportDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} formId
     * @param {UpdateFormDataDto} updateFormDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    FormsApi.prototype.formsPreviewForm = function (locationId, formId, updateFormDataDto, options) {
        var _this = this;
        return (0, exports.FormsApiFp)(this.configuration).formsPreviewForm(locationId, formId, updateFormDataDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} formId
     * @param {string} patientRecordId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    FormsApi.prototype.formsSubmitPrivateForm = function (locationId, formId, patientRecordId, options) {
        var _this = this;
        return (0, exports.FormsApiFp)(this.configuration).formsSubmitPrivateForm(locationId, formId, patientRecordId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} formId
     * @param {UpdateFormDataDto} updateFormDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    FormsApi.prototype.formsUpdateFormData = function (locationId, formId, updateFormDataDto, options) {
        var _this = this;
        return (0, exports.FormsApiFp)(this.configuration).formsUpdateFormData(locationId, formId, updateFormDataDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return FormsApi;
}(base_1.BaseAPI));
exports.FormsApi = FormsApi;
/**
 * SubmissionsApi - axios parameter creator
 * @export
 */
var SubmissionsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} submissionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsDeleteSubmission: function (locationId, formId, submissionId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('submissionsDeleteSubmission', 'locationId', locationId);
                    // verify required parameter 'formId' is not null or undefined
                    (0, common_1.assertParamExists)('submissionsDeleteSubmission', 'formId', formId);
                    // verify required parameter 'submissionId' is not null or undefined
                    (0, common_1.assertParamExists)('submissionsDeleteSubmission', 'submissionId', submissionId);
                    localVarPath = "/locations/{location_id}/forms/{form_id}/submissions/{submission_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("form_id", "}"), encodeURIComponent(String(formId)))
                        .replace("{".concat("submission_id", "}"), encodeURIComponent(String(submissionId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} submissionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsDownloadSubmissionPDF: function (locationId, formId, submissionId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('submissionsDownloadSubmissionPDF', 'locationId', locationId);
                    // verify required parameter 'formId' is not null or undefined
                    (0, common_1.assertParamExists)('submissionsDownloadSubmissionPDF', 'formId', formId);
                    // verify required parameter 'submissionId' is not null or undefined
                    (0, common_1.assertParamExists)('submissionsDownloadSubmissionPDF', 'submissionId', submissionId);
                    localVarPath = "/locations/{locationId}/forms/{formId}/submissions/{submissionId}/submissionPDF"
                        .replace("{".concat("locationId", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("formId", "}"), encodeURIComponent(String(formId)))
                        .replace("{".concat("submissionId", "}"), encodeURIComponent(String(submissionId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} submissionId
         * @param {string} fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsGetFile: function (locationId, formId, submissionId, fileName, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('submissionsGetFile', 'locationId', locationId);
                    // verify required parameter 'formId' is not null or undefined
                    (0, common_1.assertParamExists)('submissionsGetFile', 'formId', formId);
                    // verify required parameter 'submissionId' is not null or undefined
                    (0, common_1.assertParamExists)('submissionsGetFile', 'submissionId', submissionId);
                    // verify required parameter 'fileName' is not null or undefined
                    (0, common_1.assertParamExists)('submissionsGetFile', 'fileName', fileName);
                    localVarPath = "/locations/{location_id}/forms/{form_id}/submissions/{submission_id}/files/{file_name}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("form_id", "}"), encodeURIComponent(String(formId)))
                        .replace("{".concat("submission_id", "}"), encodeURIComponent(String(submissionId)))
                        .replace("{".concat("file_name", "}"), encodeURIComponent(String(fileName)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} patientRecordId
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsGetForPatientRecordId: function (patientRecordId, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'patientRecordId' is not null or undefined
                    (0, common_1.assertParamExists)('submissionsGetForPatientRecordId', 'patientRecordId', patientRecordId);
                    localVarPath = "/submissions/patientRecords/{patient_record_id}"
                        .replace("{".concat("patient_record_id", "}"), encodeURIComponent(String(patientRecordId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} submissionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsGetSubmissionById: function (locationId, formId, submissionId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('submissionsGetSubmissionById', 'locationId', locationId);
                    // verify required parameter 'formId' is not null or undefined
                    (0, common_1.assertParamExists)('submissionsGetSubmissionById', 'formId', formId);
                    // verify required parameter 'submissionId' is not null or undefined
                    (0, common_1.assertParamExists)('submissionsGetSubmissionById', 'submissionId', submissionId);
                    localVarPath = "/locations/{location_id}/forms/{form_id}/submissions/{submission_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("form_id", "}"), encodeURIComponent(String(formId)))
                        .replace("{".concat("submission_id", "}"), encodeURIComponent(String(submissionId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {FormStatus} [status]
         * @param {string} [keyword]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [orderBy]
         * @param {string} [sortBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsGetSubmissionsForLocations: function (locationId, status, keyword, limit, offset, orderBy, sortBy, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('submissionsGetSubmissionsForLocations', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/forms/submissions"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (status !== undefined) {
                        localVarQueryParameter['status'] = status;
                    }
                    if (keyword !== undefined) {
                        localVarQueryParameter['keyword'] = keyword;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (offset !== undefined) {
                        localVarQueryParameter['offset'] = offset;
                    }
                    if (orderBy !== undefined) {
                        localVarQueryParameter['order_by'] = orderBy;
                    }
                    if (sortBy !== undefined) {
                        localVarQueryParameter['sort_by'] = sortBy;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.SubmissionsApiAxiosParamCreator = SubmissionsApiAxiosParamCreator;
/**
 * SubmissionsApi - functional programming interface
 * @export
 */
var SubmissionsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SubmissionsApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} submissionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsDeleteSubmission: function (locationId, formId, submissionId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.submissionsDeleteSubmission(locationId, formId, submissionId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} submissionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsDownloadSubmissionPDF: function (locationId, formId, submissionId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.submissionsDownloadSubmissionPDF(locationId, formId, submissionId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} submissionId
         * @param {string} fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsGetFile: function (locationId, formId, submissionId, fileName, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.submissionsGetFile(locationId, formId, submissionId, fileName, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} patientRecordId
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsGetForPatientRecordId: function (patientRecordId, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.submissionsGetForPatientRecordId(patientRecordId, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} submissionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsGetSubmissionById: function (locationId, formId, submissionId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.submissionsGetSubmissionById(locationId, formId, submissionId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {FormStatus} [status]
         * @param {string} [keyword]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [orderBy]
         * @param {string} [sortBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsGetSubmissionsForLocations: function (locationId, status, keyword, limit, offset, orderBy, sortBy, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.submissionsGetSubmissionsForLocations(locationId, status, keyword, limit, offset, orderBy, sortBy, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SubmissionsApiFp = SubmissionsApiFp;
/**
 * SubmissionsApi - factory interface
 * @export
 */
var SubmissionsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SubmissionsApiFp)(configuration);
    return {
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} submissionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsDeleteSubmission: function (locationId, formId, submissionId, options) {
            return localVarFp.submissionsDeleteSubmission(locationId, formId, submissionId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} submissionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsDownloadSubmissionPDF: function (locationId, formId, submissionId, options) {
            return localVarFp.submissionsDownloadSubmissionPDF(locationId, formId, submissionId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} submissionId
         * @param {string} fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsGetFile: function (locationId, formId, submissionId, fileName, options) {
            return localVarFp.submissionsGetFile(locationId, formId, submissionId, fileName, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} patientRecordId
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsGetForPatientRecordId: function (patientRecordId, limit, options) {
            return localVarFp.submissionsGetForPatientRecordId(patientRecordId, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} formId
         * @param {string} submissionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsGetSubmissionById: function (locationId, formId, submissionId, options) {
            return localVarFp.submissionsGetSubmissionById(locationId, formId, submissionId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {FormStatus} [status]
         * @param {string} [keyword]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [orderBy]
         * @param {string} [sortBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsGetSubmissionsForLocations: function (locationId, status, keyword, limit, offset, orderBy, sortBy, options) {
            return localVarFp.submissionsGetSubmissionsForLocations(locationId, status, keyword, limit, offset, orderBy, sortBy, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SubmissionsApiFactory = SubmissionsApiFactory;
/**
 * SubmissionsApi - object-oriented interface
 * @export
 * @class SubmissionsApi
 * @extends {BaseAPI}
 */
var SubmissionsApi = /** @class */ (function (_super) {
    __extends(SubmissionsApi, _super);
    function SubmissionsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} locationId
     * @param {string} formId
     * @param {string} submissionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    SubmissionsApi.prototype.submissionsDeleteSubmission = function (locationId, formId, submissionId, options) {
        var _this = this;
        return (0, exports.SubmissionsApiFp)(this.configuration).submissionsDeleteSubmission(locationId, formId, submissionId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} formId
     * @param {string} submissionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    SubmissionsApi.prototype.submissionsDownloadSubmissionPDF = function (locationId, formId, submissionId, options) {
        var _this = this;
        return (0, exports.SubmissionsApiFp)(this.configuration).submissionsDownloadSubmissionPDF(locationId, formId, submissionId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} formId
     * @param {string} submissionId
     * @param {string} fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    SubmissionsApi.prototype.submissionsGetFile = function (locationId, formId, submissionId, fileName, options) {
        var _this = this;
        return (0, exports.SubmissionsApiFp)(this.configuration).submissionsGetFile(locationId, formId, submissionId, fileName, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} patientRecordId
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    SubmissionsApi.prototype.submissionsGetForPatientRecordId = function (patientRecordId, limit, options) {
        var _this = this;
        return (0, exports.SubmissionsApiFp)(this.configuration).submissionsGetForPatientRecordId(patientRecordId, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} formId
     * @param {string} submissionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    SubmissionsApi.prototype.submissionsGetSubmissionById = function (locationId, formId, submissionId, options) {
        var _this = this;
        return (0, exports.SubmissionsApiFp)(this.configuration).submissionsGetSubmissionById(locationId, formId, submissionId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {FormStatus} [status]
     * @param {string} [keyword]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [orderBy]
     * @param {string} [sortBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionsApi
     */
    SubmissionsApi.prototype.submissionsGetSubmissionsForLocations = function (locationId, status, keyword, limit, offset, orderBy, sortBy, options) {
        var _this = this;
        return (0, exports.SubmissionsApiFp)(this.configuration).submissionsGetSubmissionsForLocations(locationId, status, keyword, limit, offset, orderBy, sortBy, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SubmissionsApi;
}(base_1.BaseAPI));
exports.SubmissionsApi = SubmissionsApi;
